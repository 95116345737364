<template>
  <div>
    <!-- Meta Title -->
    <div v-bind:id="componentId" class="form-group mb-3">
      <div class="row">
        <div class="col">
          <label v-bind:for="componentId + '-title'" class="custom-label"
            >Meta Başlık
            <i
              class="fas fa-question-circle ml-1"
              v-tooltip.top="
                $t('recommendedNumberOfCharacters') + ' <br /> <b>35 - 60</b>'
              "
            ></i
          ></label>
        </div>
        <div class="col-auto" v-if="showLimits">
          <span class="font-12">
            {{ currentTitleLength }} / {{ titleLength }}
          </span>
        </div>
      </div>
      <input
        v-if="!useEditor"
        type="text"
        v-bind:id="componentId + '-title'"
        class="form-control"
        v-model="computedData.title"
        v-bind:maxlength="titleLength"
      />
      <WeEditor
        v-else
        v-model="computedData.title"
        v-bind:mention-feed="mentions"
        v-bind:show-toolbar="false"
      />
    </div>
    <!-- Meta Title -->

    <!-- Meta Keywords -->
    <div class="form-group mb-3">
      <div class="row">
        <div class="col">
          <label v-bind:for="componentId + '-keywords'" class="custom-label"
            >Meta Anahtar Kelimeleri
            <i
              class="fas fa-question-circle ml-1"
              v-tooltip.top="
                $t('itDoesNotAffectGoogleRankingsItsUseIsNotRecommended')
              "
            ></i
          ></label>
        </div>
        <div class="col-auto" v-if="showLimits">
          <span class="font-12">
            {{ currentKeywordsLength }} / {{ keywordsLength }}
          </span>
        </div>
      </div>
      <input
        v-if="!useEditor"
        type="text"
        v-bind:id="componentId + '-keywords'"
        class="form-control"
        v-model="computedData.keywords"
        v-bind:maxlength="keywordsLength"
      />
      <WeEditor
        v-else
        v-model="computedData.keywords"
        v-bind:mention-feed="mentions"
        v-bind:show-toolbar="false"
      />
    </div>
    <!-- Meta Keywords -->

    <!-- Meta Description -->
    <div class="form-group mb-3">
      <div class="row">
        <div class="col">
          <label v-bind:for="componentId + '-description'" class="custom-label"
            >Meta Açıklama
            <i
              class="fas fa-question-circle ml-1"
              v-tooltip.top="
                $t('recommendedNumberOfCharacters') + ' <br /> <b>130 - 350</b>'
              "
            ></i
          ></label>
        </div>
        <div class="col-auto" v-if="showLimits">
          <span class="font-12">
            {{ currentDescriptionLength }} / {{ descriptionLength }}
          </span>
        </div>
      </div>
      <textarea
        v-if="!useEditor"
        v-bind:id="componentId + '-description'"
        class="form-control"
        style="min-height: 250px"
        v-model="computedData.description"
        v-bind:maxlength="descriptionLength"
      ></textarea>
      <WeEditor
        v-else
        v-model="computedData.description"
        v-bind:mention-feed="mentions"
        v-bind:show-toolbar="false"
      />
    </div>
    <!-- Meta Description -->

    <!-- Meta Robots -->
    <div class="form-group mb-3" v-if="showRobots">
      <label v-bind:for="componentId + '-description'" class="custom-label">{{
        $t("metaRobotsTag")
      }}</label>
      <div class="row">
        <div
          class="col-auto"
          v-for="(opt, index) in robotOptions"
          v-bind:key="index"
        >
          <WeSwitch
            v-bind:label="opt"
            v-bind:value="getRobotValue(opt)"
            v-bind:disabled="checkDisabled(opt)"
            v-on:input="changeRobot(opt, $event)"
          />
        </div>
        <!-- <v-select
          v-bind:options="['index', 'noindex', 'follow', 'nofollow']"
          v-model="data.robots"
          multiple
          v-bind:close-on-select="false"
        ></v-select> -->
      </div>
    </div>
    <!-- ./Meta Robots -->

    <!-- Canonical URL -->
    <WeInput
      v-if="showCanonical"
      v-bind:label="$t('canonicalUrl')"
      v-model="computedData.canonicalUrl"
    />
    <!-- ./Canonical URL -->
  </div>
</template>

<script>
export default {
  name: "WeMetaData",
  data() {
    return {
      robotOptions: ["index", "noindex", "follow", "nofollow"],
    };
  },
  props: {
    data: {
      title: {
        default: "",
      },
      keywords: {
        default: "",
      },
      description: {
        default: "",
      },
      canonicalUrl: {
        default: "",
      },
      robots: {
        default: () => ["index", "follow"],
      },
    },
    titleLength: {
      default: 70,
    },
    keywordsLength: {
      default: 100,
    },
    descriptionLength: {
      default: 350,
    },
    showRobots: {
      default: true,
    },
    showLimits: {
      default: true,
    },
    showCanonical: {
      default: true,
    },
    useEditor: {
      default: false,
    },
    mentions: {
      default: () => [],
    },
  },
  model: {
    prop: "data",
  },
  methods: {
    changeRobot(opt, e) {
      const robots = this.data.robots;
      const index = this.findOptionIndex(opt);

      if (index < 0 && e === true) {
        robots.push(opt);
      }
      if (index >= 0 && e !== true) {
        robots.splice(index, 1);
      }
    },
    checkDisabled(opt) {
      const robots = this.data.robots;
      if (robots && robots.length) {
        switch (opt) {
          case "index":
            return robots.includes("noindex");
          case "noindex":
            return robots.includes("index");
          case "follow":
            return robots.includes("nofollow");
          case "nofollow":
            return robots.includes("follow");
        }
      } else {
        return false;
      }
    },
    getRobotValue(opt) {
      const robots = this.data.robots;
      if (robots && robots.length) {
        return robots.includes(opt);
      }
      return false;
    },
    findOptionIndex(opt) {
      if (!this.data.robots) return -1;
      return this.data.robots.findIndex((item) => item == opt);
    },
  },
  computed: {
    componentId() {
      return "wemetadata-" + helper.getRandomInteger(1, 999999);
    },
    computedData() {
      return this.data;
    },
    currentTitleLength() {
      return this.computedData.title ? this.computedData.title.length : 0;
    },
    currentKeywordsLength() {
      return this.computedData.keywords ? this.computedData.keywords.length : 0;
    },
    currentDescriptionLength() {
      return this.computedData.description
        ? this.computedData.description.length
        : 0;
    },
  },
};
</script>

<style lang="scss">
.ck-content {
  .mention {
    background: #3490dc4d !important;
    color: var(--blue) !important;
    border: 1px solid var(--blue) !important;
    padding: 3px;
    font-size: 13px;
    border-radius: 0.25rem;
  }
}
.offer-info {
  span {
    margin-left: 20px !important;
  }
  &.ck-on {
    background: var(--teal) !important;
    span {
      color: var(--dark) !important;
    }
  }
}
</style>
